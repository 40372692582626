/* eslint-disable @typescript-eslint/camelcase */
type Skill = {
  id: number;
  name: string;
  order: number;
  parent_id: number;
  updated_at: string;
  created_at: string;
  worker_description: string;
  company_description: string;
};

interface SkillRate {
  skill_name: string | null;
  skill_id: number | null;
  level_id: number | null;
  branch_id: number | null;
  night_bonus: number | string;
  weekend_bonus: number | string;
  level_bonus: number | string;
  base_rate: number | string;
}

function initSkillRate(): SkillRate {
  const defaults = {
    night_bonus: "",
    weekend_bonus: "",
    base_rate: "",
    level_bonus: "",
    skill_id: null,
    skill_name: null,
    level_id: null,
    branch_id: null
  } as SkillRate;

  return defaults;
}

export { Skill, SkillRate, initSkillRate };
