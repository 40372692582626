
/* eslint-disable @typescript-eslint/camelcase */
import _ from "lodash";
import { computed, defineComponent, reactive, PropType, onMounted } from "vue";
import * as types from "@/components/worker-rates/misc/types";
import LoadingSpinner from "@/components/general/LoadingSpinner.vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "customize-rate-modal",
  components: {
    LoadingSpinner
  },
  props: {
    skillID: {
      type: Number as PropType<number>,
      required: true
    },
    branchID: Number as PropType<number>
  },
  setup(props, { emit }) {
    const store = useStore();
    const model = reactive({});
    let existingRate = {} as types.SkillRate;
    const isSavingRate = computed(
      () => store.getters["WorkerRatesModule/isSaving"]
    );
    const isLoading = computed(
      () => store.getters["WorkerRatesModule/isFetchingSingle"]
    );
    const customRates = computed(
      () => store.getters["WorkerRatesModule/customRates"]
    );
    const rates = computed(
      () => store.getters["WorkerRatesModule/existingRates"]
    );
    const levels = computed(() => store.getters["WorkerRatesModule/levels"]);
    const orderedLevels = computed(() => {
      return _.orderBy(levels.value, "weight", "desc");
    });
    const getLevelBonus = (level_id: number): number => {
      const level = levels.value.find(level => level.id == level_id);
      if (level) {
        return existingRate.level_bonus != "" &&
          typeof existingRate.level_bonus == "string"
          ? parseInt(existingRate.level_bonus) * level.weight
          : 0;
      }
      return 0;
    };
    const getPlaceholder = (
      skill_id: number,
      field: string
    ): string | number => {
      if (_.isEmpty(existingRate)) {
        existingRate = rates.value.find(rate => rate.skill_id == skill_id);
      }
      return _.isEmpty(existingRate) ? "" : existingRate[field];
    };
    const getLevelBaseRate = (
      value: string | number,
      level_id: number
    ): string | number => {
      return value != "" && typeof value == "string"
        ? parseInt(value) + getLevelBonus(level_id)
        : "";
    };
    const buildModel = () => {
      if (orderedLevels.value.length > 0) {
        for (let i = 0; i < orderedLevels.value.length; i++) {
          const entity = types.initSkillRate();
          entity.level_id = orderedLevels.value[i].id;
          entity.skill_id = props.skillID ? props.skillID : null;
          entity.branch_id = props.branchID ? props.branchID : null;
          if (
            customRates.value.some(rate => rate.level_id == orderedLevels.value[i].id)
          ) {
            const customRate = customRates.value.find(
              rate => rate.level_id == orderedLevels.value[i].id
            );
            entity.base_rate = customRate.base_rate;
            entity.night_bonus = customRate.night_bonus;
            entity.weekend_bonus = customRate.weekend_bonus;
          }
          model[orderedLevels.value[i].id] = entity;
        }
      }
    };
    const handleSave = () => {
      store.dispatch("WorkerRatesModule/store", model).then(() => {
        emit("success");
      });
    };
    const levelName = (id: number) => {
      if (levels.value.length > 0) {
        return levels.value.find(level => level.id == id).name;
      }
    };
    onMounted(() => {
      if (props.skillID) {
        store
          .dispatch("WorkerRatesModule/fetchOne", {
            skill_id: props.skillID,
            branch_id: props.branchID
          })
          .then(() => {
            buildModel();
          });
      }
    });
    return {
      isSavingRate,
      levelName,
      getPlaceholder,
      getLevelBaseRate,
      orderedLevels,
      handleSave,
      model,
      isLoading
    };
  }
});
