
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  watch,
  onUpdated,
  PropType,
  reactive,
  ref
} from "vue";
import CustomizeRateModal from "@/components/worker-rates/misc/customize-modal.vue";
import * as types from "@/components/worker-rates/misc/types";
import { Modal } from "bootstrap";
import { useStore } from "vuex";
import Inputmask from "inputmask";
import Swal from "sweetalert2";

export default defineComponent({
  /* eslint-disable @typescript-eslint/camelcase */
  name: "worker-rates-data-table",
  components: {
    CustomizeRateModal
  },
  props: {
    selectedSkill: {
      type: [String, Number] as PropType<number | string>
    },
    branchID: {
      type: Number as PropType<number>,
      required: true
    },
    isModal: {
      type: Boolean as PropType<boolean>,
      default: false
    },
    missingRatesSkills: {
      type: Array as PropType<types.Skill[]>,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const store = useStore();
    const key = ref<number>(0);
    const model = reactive({});
    const modal = ref();
    const selectedSkillID = ref<number>();
    const levels = computed(() => store.getters["WorkerRatesModule/levels"]);
    const skills = computed(() => store.getters["WorkerRatesModule/skills"]);
    const tableKey = ref<number>(0);
    const bulkEditList = ref<number[]>([]);
    const bulkEdit = reactive({
      base_rate: "",
      weekend_bonus: "",
      night_bonus: "",
      level_bonus: ""
    });
    const isSavingRate = computed(
      () => store.getters["WorkerRatesModule/isSaving"]
    );
    const existingRates = computed(
      () => store.getters["WorkerRatesModule/existingRates"]
    );
    const buildModel = skills => {
      for (let i = 0; i < skills.length; i++) {
        const skillRate = types.initSkillRate();
        if (
          existingRates.value.length > 0 &&
          existingRates.value.some(e => e.skill_id === skills[i].id)
        ) {
          const existingRate = existingRates.value.find(
            e => e.skill_id == skills[i].id
          );
          skillRate.night_bonus = existingRate.night_bonus;
          skillRate.weekend_bonus = existingRate.weekend_bonus;
          skillRate.base_rate = existingRate.base_rate;
          skillRate.level_bonus = existingRate.level_bonus;
        }
        skillRate.skill_name = skills[i].name;
        skillRate.skill_id = skills[i].id;
        skillRate.branch_id = props.branchID;
        model[skills[i].id] = skillRate;
      }
    };
    const subSkills = computed((): types.Skill[] => {
      if (props.isModal) {
        buildModel(props.missingRatesSkills);
        return props.missingRatesSkills;
      }
      const skill = skills.value.find(
        (skill: types.Skill) => skill.id == props.selectedSkill
      );
      if (skill && skill.children) {
        buildModel(skill.children);
        return skill.children;
      }
      return [];
    });

    onUpdated(() => {
      Inputmask({
        //Allows only positive numbers and decimals up to 2 places
        alias: "numeric",
        allowMinus: false,
        digits: 2
      }).mask("#maskedInput");
    });
    const saveRates = () => {
      emit("saveRates", model);
    };
    const openCustomizeModal = async (skill_id: number) => {
      selectedSkillID.value = skill_id;
      key.value++;
      await nextTick().then(() => {
        modal.value = new Modal(
          document.getElementById("customize_rate_modal")
        );
        modal.value.show();
      });
    };
    const applyBulkEdit = () => {
      bulkEditList.value.forEach(skill => {
        model[skill].base_rate =
          bulkEdit.base_rate != ""
            ? bulkEdit.base_rate
            : model[skill].base_rate;
        model[skill].night_bonus =
          bulkEdit.night_bonus != ""
            ? bulkEdit.night_bonus
            : model[skill].night_bonus;
        model[skill].weekend_bonus =
          bulkEdit.weekend_bonus != ""
            ? bulkEdit.weekend_bonus
            : model[skill].weekend_bonus;
        model[skill].level_bonus =
          bulkEdit.level_bonus != ""
            ? bulkEdit.level_bonus
            : model[skill].level_bonus;
      });
      tableKey.value++;
    };
    const handleSelect = (skill_id: number): void => {
      if (bulkEditList.value.includes(skill_id)) {
        bulkEditList.value = bulkEditList.value.filter(e => e != skill_id);
      } else {
        bulkEditList.value.push(skill_id);
      }
    };
    const handleSelectAllRows = (event: boolean): void => {
      if (event) {
        bulkEditList.value = [];
        Object.keys(model).forEach(key => {
          bulkEditList.value.push(parseInt(key));
        });
      } else {
        bulkEditList.value = [];
      }
      tableKey.value++;
    };
    const handleSuccessfulSave = async () => {
      modal.value.hide();
      Swal.fire({
        position: "top-end",
        toast: true,
        icon: "success",
        title: "Rate saved succesfully",
        showConfirmButton: false,
        timer: 1000
      });
    };
    watch(model, () => {
      if (props.isModal)
        emit("updateModalValues", { values: model, branchId: props.branchID });
    });
    const handleInput = data => {
      emit("hasChanges", true);

      model[data.id][data.field] = data.value;
    };
    return {
      openCustomizeModal,
      selectedSkillID,
      tableKey,
      handleSelect,
      bulkEdit,
      handleSelectAllRows,
      bulkEditList,
      applyBulkEdit,
      handleInput,
      handleSuccessfulSave,
      isSavingRate,
      saveRates,
      subSkills,
      model,
      levels,
      key
    };
  }
});
